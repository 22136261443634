import * as React from 'react';
import {
  Grid,
  Card,
  Button,
  CardHeader,
  Avatar,
  IconButton,
  CardContent,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  makeStyles,
  TextField,
} from '@material-ui/core';
import { AttendanceLog } from 'lib/Model/AttendanceLog';
import { DelayedLinearProgress, EmptyView, SnackbarContext } from 'components';
import { addDays, format, formatDistanceToNow, parse, subDays } from 'date-fns';
import { Refresh } from '@material-ui/icons';
import { useDebounce, useFetch } from 'hooks';
import { SortableTable } from 'components/SortableTable';
import { SortableTableHeader } from 'components/SortableTable/components';
import { Alert, Autocomplete } from '@material-ui/lab';
import { Config, DateFormat, DateTimeFormat } from 'config';
import { KeyboardDatePicker } from '@material-ui/pickers';

import { Download, objectToUrlParams } from 'lib';
import { PopperDropdownMenu } from 'components/PopperDropdownMenu';
import { Department } from 'lib/Model/Department';
import { Device } from 'lib/Model/Device';
import { Employee } from 'lib/Model/Employee';

interface State {
  deviceId: number;
  userId: number;
  department: string;
  dateFrom: Date | null;
  dateTo: Date | null;
  devices: Device[] | undefined;
  employees: Employee[] | undefined;
  departments: Department[] | undefined;
}

const useStyles = makeStyles((theme) => ({
  tableWrapper: {
    // Needs updating whenever content height above the table changes
    maxHeight: 'calc(100vh - 180px)',
    overflow: 'auto',
  },
}));

export const Transactions: React.FC = () => {
  const classes = useStyles();
  const [transactions, setTransactions] = React.useState<
    AttendanceLog[] | undefined
  >();

  const snackbar = React.useContext(SnackbarContext);

  const { loading: fetchLoading, sendRequest, error } = useFetch();

  const {
    loading: uploadLoading,
    sendRequest: uploadRequest,
    error: uploadError,
  } = useFetch();

  const {
    loading: downloadLoading,
    sendRequest: downloadRequest,
    error: downloadError,
  } = useFetch();

  const {
    loading: loadingDevices,
    sendRequest: sendDevicesRequest,
  } = useFetch();

  const {
    loading: loadingEmployees,
    sendRequest: sendEmployeesRequest,
  } = useFetch();

  const {
    loading: loadingDepartments,
    sendRequest: sendDepartmentRequest,
  } = useFetch();

  const [filterParams, setFilterParams] = React.useState<State>({
    deviceId: -1,
    userId: 0,
    department: '',
    dateFrom: subDays(new Date(), 2),
    dateTo: addDays(new Date(), 1),
    devices: undefined,
    employees: undefined,
    departments: undefined,
  });

  const debouncedFilterParams = useDebounce(filterParams, 1000);

  React.useEffect(() => {
    async function fetchData() {
      try {
        const response = await sendDevicesRequest(`api/devices`, {});
        const jsonResponse = await response.json();
        if (jsonResponse) {
          setFilterParams((p) => ({
            ...p,
            deviceId: 0,
            devices: jsonResponse,
          }));
        }
      } catch (ex) {}
    }
    fetchData();
  }, [sendDevicesRequest, setFilterParams]);

  React.useEffect(() => {
    async function fetchData() {
      try {
        const response = await sendEmployeesRequest(`api/users`, {});
        const jsonResponse = await response.json();
        if (jsonResponse) {
          setFilterParams((p) => ({
            ...p,
            employees: jsonResponse,
          }));
        }
      } catch (ex) {}
    }
    fetchData();
  }, [setFilterParams, sendEmployeesRequest]);

  React.useEffect(() => {
    async function fetchData() {
      try {
        const response = await sendDepartmentRequest(`api/departments`, {});
        const jsonResponse = await response.json();
        if (jsonResponse) {
          setFilterParams((p) => ({
            ...p,
            departments: jsonResponse,
          }));
        }
      } catch (ex) {}
    }
    fetchData();
  }, [setFilterParams, sendDepartmentRequest]);

  React.useEffect(() => {
    async function fetchData() {
      if (
        !debouncedFilterParams.employees ||
        !debouncedFilterParams.departments ||
        debouncedFilterParams.deviceId < 0
      ) {
        return;
      }
      try {
        const {
          dateFrom,
          dateTo,
          userId,
          deviceId,
          department,
        } = debouncedFilterParams;
        const queryParams = new URLSearchParams({
          dateFrom: dateFrom !== null ? format(dateFrom, DateFormat.INPUT) : '',
          dateTo: dateTo !== null ? format(dateTo, DateFormat.INPUT) : '',
          deviceId: deviceId > 0 ? deviceId.toString() : '',
          userId: userId > 0 ? userId.toString() : '',
          department: department ? department.toString() : '',
        });
        const response = await sendRequest(
          `api/transactions/attendance?${queryParams}`,
          {},
        );
        const jsonResponse = await response.json();
        if (jsonResponse) {
          setTransactions(jsonResponse);
        }
      } catch (ex) {}
    }
    fetchData();
  }, [setTransactions, sendRequest, debouncedFilterParams]);

  const columns: SortableTableHeader[] = [
    { key: 'time', label: 'Punch time' },
    {
      key: 'avatar',
      label: '',
      props: { style: { width: 50, textAlign: 'center' } },
    },
    { key: 'name', label: 'Employee', sortable: true },
    { key: 'departmentCode', label: 'Department Code', sortable: true },
    { key: 'departmentName', label: 'Department Name', sortable: true },
    { key: 'device', label: 'Device', sortable: true },
    { key: 'verify', label: 'Verification type', sortable: true },
    { key: 'punchOption', label: 'Punch option', sortable: true },
    { key: 'workCode', label: 'Work code', sortable: true },
    { key: 'created', label: 'Date uploaded', sortable: true },
  ];

  const rows =
    transactions?.map((transaction) => {
      const createdOn = parse(
        transaction.createdOn,
        DateTimeFormat.GQL,
        new Date(),
      );
      const logTime = parse(transaction.time, DateTimeFormat.GQL, new Date());
      return {
        key: transaction.id.toString(),
        cells: [
          {
            key: 'time',
            display: format(logTime, DateTimeFormat.LONG),
            sortValue: logTime.getTime(),
          },
          {
            key: 'avatar',
            display: (
              <Avatar
                src={
                  transaction.user?.userPicFileName
                    ? `${Config.API_BASE_URL}${transaction.user.userPicFileName}`
                    : undefined
                }
              />
            ),
            sortValue: transaction.id,
          },
          {
            key: 'fullName',
            display: (
              <React.Fragment>
                <Typography variant="body1">
                  {transaction.user?.name ?? 'N/A'}
                </Typography>
                {transaction.user?.employeeNumber && (
                  <Typography variant="caption">
                    {transaction.user?.employeeNumber}
                  </Typography>
                )}
              </React.Fragment>
            ),
            sortValue: transaction.user?.name ?? 'N/A',
          },
          {
            key: 'departmentCode',
            display: transaction.user?.departmentCode ?? 'N/A',
            sortValue: transaction.user?.departmentCode ?? 'N/A',
          },
          {
            key: 'departmentName',
            display: transaction.user?.departmentName ?? 'N/A',
            sortValue: transaction.user?.departmentName ?? 'N/A',
          },
          {
            key: 'device',
            display: transaction.device?.name ?? 'N/A',
            sortValue: transaction.verify,
          },
          {
            key: 'verify',
            display: transaction.verifyType,
            sortValue: transaction.verify,
          },
          {
            key: 'punchOption',
            display: transaction.punchState ?? transaction.status,
            sortValue: transaction.status,
          },
          {
            key: 'workCode',
            display: transaction.workcode,
            sortValue: transaction.workcode,
          },
          {
            key: 'created',
            display: `${formatDistanceToNow(createdOn)} ago`,
            sortValue: createdOn.getTime() ?? -1,
          },
        ],
      };
    }) ?? undefined;

  return (
    <Grid container>
      <Grid item xs={12}>
        <Card>
          <CardHeader
            title="Transactions"
            action={
              <React.Fragment>
                <input
                  id="icon-button-file"
                  type="file"
                  onChange={onUploadFile}
                  style={{ display: 'none' }}
                />

                <label htmlFor="icon-button-file">
                  <Button
                    color="default"
                    component="span"
                    disabled={uploadLoading}
                  >
                    Import from USB
                  </Button>
                </label>

                <PopperDropdownMenu
                  label="Exports"
                  menuItems={[
                    {
                      label: 'Grouped Transactions Export',
                      onClick: () => exportReport(true),
                    },
                    {
                      label: 'Raw Transactions Export',
                      onClick: () => exportReport(false),
                    },
                    {
                      label: 'Swipes Format Export',
                      onClick: () => exportSwipes(),
                    },
                  ]}
                />

                <IconButton
                  color="secondary"
                  disabled={fetchLoading}
                  onClick={async () => {
                    try {
                      const {
                        dateFrom,
                        dateTo,
                        userId,
                        deviceId,
                        department,
                      } = filterParams;
                      const queryParams = new URLSearchParams({
                        dateFrom:
                          dateFrom !== null
                            ? format(dateFrom, DateFormat.INPUT)
                            : '',
                        dateTo:
                          dateTo !== null
                            ? format(dateTo, DateFormat.INPUT)
                            : '',
                        deviceId: deviceId ? deviceId.toString() : '',
                        userId: userId ? userId.toString() : '',
                        department: department ? department.toString() : '',
                      });
                      const response = await sendRequest(
                        `api/transactions/attendance?${queryParams}`,
                        {},
                      );
                      setTransactions(await response.json());
                    } catch (ex) {}
                  }}
                >
                  <Refresh />
                </IconButton>
              </React.Fragment>
            }
          />

          <CardContent>
            <Grid container>
              <Grid item xs={12} md={3}>
                <KeyboardDatePicker
                  label="Date from"
                  onChange={(date) =>
                    setFilterParams((p) => ({ ...p, dateFrom: date }))
                  }
                  value={filterParams.dateFrom}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <KeyboardDatePicker
                  label="Date to"
                  onChange={(date) =>
                    setFilterParams((p) => ({ ...p, dateTo: date }))
                  }
                  value={filterParams.dateTo}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl margin="none" fullWidth>
                  <InputLabel id="device-input">Device</InputLabel>
                  <Select
                    value={filterParams.deviceId}
                    onChange={(e) =>
                      setFilterParams((p) => ({
                        ...p,
                        deviceId: e.target.value
                          ? parseInt(e.target.value as string, 10)
                          : 0,
                      }))
                    }
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                  >
                    <MenuItem value={0}>All devices</MenuItem>
                    {filterParams.devices &&
                      filterParams.devices.map((device) => (
                        <MenuItem key={device.id} value={device.id}>
                          {device.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl margin="none" fullWidth>
                  <Autocomplete
                    fullWidth
                    value={filterParams.employees?.find(
                      (e) => e.id === filterParams.userId,
                    )}
                    onChange={(e, user) =>
                      setFilterParams((p) => ({
                        ...p,
                        userId: user?.id ?? 0,
                      }))
                    }
                    options={filterParams.employees ?? []}
                    getOptionSelected={(sel) => sel.id === filterParams.userId}
                    getOptionLabel={(opt) =>
                      `${opt.name} ${`(${opt.employeeNumber})`}`
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="All employees"
                        label="Employee"
                        fullWidth
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl margin="none" fullWidth>
                  <Autocomplete
                    fullWidth
                    value={filterParams.departments?.find(
                      (e) =>
                        [e.departmentCode, e.departmentName].join('|') ===
                        filterParams.department,
                    )}
                    onChange={(e, department) =>
                      setFilterParams((p) => ({
                        ...p,
                        department: department
                          ? [
                              department.departmentCode,
                              department.departmentName,
                            ].join('|')
                          : '',
                      }))
                    }
                    options={filterParams.departments ?? []}
                    getOptionSelected={(sel) =>
                      [sel.departmentCode, sel.departmentName].join('|') ===
                      filterParams.department
                    }
                    getOptionLabel={(opt) =>
                      opt.departmentName || opt.departmentCode
                        ? `${opt.departmentName} ${`(${opt.departmentCode})`}`
                        : 'No Department Set'
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="All departments"
                        label="Department"
                        fullWidth
                      />
                    )}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </CardContent>

          <DelayedLinearProgress
            loading={
              fetchLoading ||
              loadingDevices ||
              loadingEmployees ||
              loadingDepartments ||
              downloadLoading
            }
          />

          {!rows?.length ? (
            rows !== undefined && <EmptyView />
          ) : (
            <React.Fragment>
              <CardContent>
                <Typography variant="body1">
                  Showing {rows.length} results.
                </Typography>
              </CardContent>
              <div className={classes.tableWrapper}>
                <SortableTable
                  columns={columns}
                  rows={rows}
                  tableProps={{ size: 'small' }}
                  defaultSort={{ columnKey: 'name', order: 'asc' }}
                  loading={fetchLoading}
                  emptyTableText="No transactions found"
                />
              </div>
            </React.Fragment>
          )}
          {error && (
            <CardContent>
              <Alert severity="error">
                <Typography variant="body1">{error.message}</Typography>
              </Alert>
            </CardContent>
          )}
        </Card>
      </Grid>
    </Grid>
  );

  async function exportReport(convertIntoTimelogs: boolean) {
    const { dateFrom, dateTo, userId, deviceId, department } = filterParams;
    const params = {
      dateFrom: dateFrom !== null ? format(dateFrom, DateFormat.INPUT) : '',
      dateTo: dateTo !== null ? format(dateTo, DateFormat.INPUT) : '',
      deviceId: deviceId ? deviceId.toString() : '',
      userId: userId ? userId.toString() : '',
      department: department ? department.toString() : '',
      convertIntoTimelogs: convertIntoTimelogs ? '1' : '',
      format: 'csv',
      includeHeader: '1',
    };

    const response = await downloadRequest(
      `api/transactions/attendance?${objectToUrlParams(params)}`,
      {},
    );
    if (downloadError) {
      snackbar.error(downloadError);
      return;
    }
    const csvContent = await response.text();
    Download(`${Object.values(params).join('_')}.csv`, csvContent);
  }

  async function exportSwipes() {
    const { dateFrom, dateTo, userId, deviceId, department } = filterParams;
    const params = {
      dateFrom: dateFrom !== null ? format(dateFrom, DateFormat.INPUT) : '',
      dateTo: dateTo !== null ? format(dateTo, DateFormat.INPUT) : '',
      deviceId: deviceId ? deviceId.toString() : '',
      userId: userId ? userId.toString() : '',
      department: department ? department.toString() : '',
      format: 'csv',
      includeHeader: '1',
    };

    const response = await downloadRequest(
      `api/transactions/megabyteExport?${objectToUrlParams(params)}`,
      {},
    );
    if (downloadError) {
      snackbar.error(downloadError);
      return;
    }
    const csvContent = await response.text();
    Download(`${Object.values(params).join('_')}.csv`, csvContent);
  }

  async function onUploadFile(e: React.ChangeEvent<HTMLInputElement>) {
    const { dateFrom, dateTo, userId, deviceId, department } = filterParams;
    const { currentTarget } = e;
    const files = currentTarget.files;

    try {
      if (!files || !files.length) {
        throw new Error('Select a file.');
      }

      if (!deviceId) {
        currentTarget.value = '';
        throw new Error('Select a device to link the uploaded clockings');
      }

      const file = files[0];

      // Clear input (onChange won't fire if same file is uploaded)
      currentTarget.value = '';
      const uploadResponse = await uploadRequest(
        `api/transaction-upload/${deviceId}`,
        {
          file,
        },
      );

      if (uploadError) {
        snackbar.error(uploadError);
        return;
      }

      const jsonResponse = await uploadResponse.json();
      snackbar.success(
        `Import complete: ${jsonResponse.newCount} new clockings.`,
      );

      const queryParams = new URLSearchParams({
        dateFrom: dateFrom !== null ? format(dateFrom, DateFormat.INPUT) : '',
        dateTo: dateTo !== null ? format(dateTo, DateFormat.INPUT) : '',
        deviceId: deviceId ? deviceId.toString() : '',
        userId: userId ? userId.toString() : '',
        department: department ? department.toString() : '',
      });
      const response = await sendRequest(
        `api/transactions/attendance?${queryParams}`,
        {},
      );
      const getJsonResponse = await response.json();
      if (getJsonResponse) {
        setTransactions(getJsonResponse);
      }
    } catch (error) {
      snackbar.error(error);
    }
  }
};
